import { NextPage } from 'next'

import Loader from 'components/Loader'
import Redirect from 'components/Redirect'
import { useOrganization } from 'hooks/useOrganization'

const nextOnboardingStepMap = {
	'configuration-setup': 'offer-support',
	'google-reviews': 'google-reviews',
	'whatsapp-widget': 'provide-self-help',
	'offer-support': 'provide-self-help',
	'provide-self-help': 'custom-links',
	'custom-links': 'customize',
	customize: 'add-to-website',
}

const Home: NextPage = () => {
	const { organization, isOrganizationLoading } = useOrganization()
	const onboardingProgress = organization?.onboardingProgress

	if (isOrganizationLoading) {
		return <Loader fullPage />
	}

	if (!onboardingProgress) {
		return <Redirect to="/hello" />
	}

	if (onboardingProgress && onboardingProgress !== 'completed') {
		return <Redirect to={`/onboarding/${nextOnboardingStepMap[onboardingProgress]}`} />
	}

	return <Redirect to="/configurator" />
}

export default Home
