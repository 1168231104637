import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'

import { LogoSignetIcon } from 'components/icons'
import { getIsSafari } from 'lib/browsers'
import { pulse, rotation } from 'styles/animations'

const isSafari = typeof window !== 'undefined' && getIsSafari()

export const AnimatedSignetIcon = styled(LogoSignetIcon)`
	.owLogoSignetGradient {
		background: conic-gradient(from 0deg, #994bf4, #ff3f00, #ffd000, #00d969, #0246ff, #9a4cf5);
	}
	${isSafari
		? css`
				animation: ${pulse} 1.5s ease-in-out infinite;
				.owLogoSignetGradient {
					width: 100%;
					height: 100%;
				}
		  `
		: css`
				.owLogoSignetGradient {
					width: 60px;
					height: 60px;
					position: relative;
					left: -6px;
					top: -8px;
					animation: ${rotation} 1.5s linear infinite;
				}
		  `};
`

const LoadingContainer = styled(m.div)<{ fullPage: boolean }>`
	width: 100%;
	height: ${({ fullPage }) => (fullPage ? '100vh' : '100%')};
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

type Props = {
	children?: React.ReactNode
	delay?: number
	fullPage?: boolean
}

function Loader({ children, delay = 0, fullPage = false }: Props) {
	return (
		<LoadingContainer
			key="loader"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ delay }}
			fullPage={fullPage}
		>
			<AnimatedSignetIcon />
			<m.div
				key="loader-children"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: '100%' }}
			>
				{children}
			</m.div>
		</LoadingContainer>
	)
}

export default Loader
